
















































import { computed, defineComponent, reactive, ref, set } from '@vue/composition-api'
import { exportNlpApi, getNlpConfigDetailApi, getProjectApi, importNlpApi } from '@/api/project'
import { exportFile, showError } from '@/utils/common'
import ProjectHeader from './components/ProjectHeader.vue'
import { EntityType, ProjectStatus } from '@/api/project/model'
import { Upload } from 'element-ui'
import { excelTypes, uploadHandle } from '@/utils/upload'

export default defineComponent({
    components: {
        ProjectHeader,
        [Upload.name]: Upload
    },
    setup(props, { root }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const projectObj: any = reactive({})
        const configObj: any = reactive({})
        const calculateInfo = reactive({
            loading: false,
            progress: '0%'
        })

        const getProject = async () => {
            try {
                const data = await getProjectApi({
                    uuid: projectUuId.value
                })
                Object.keys(data).forEach((key) => {
                    set(projectObj, key, data[key])
                })
            } catch (error) {
                showError(error)
            }
        }
        getProject()

        const getNlpConfigDetail = async () => {
            try {
                const data = await getNlpConfigDetailApi({
                    uuid: projectUuId.value
                })
                Object.keys(data).forEach((key) => {
                    set(configObj, key, data[key])
                })
            } catch (error) {
                showError(error)
            }
        }
        getNlpConfigDetail()

        const getCalculateProgress = async () => {
            try {
                const data = await getNlpConfigDetailApi({
                    uuid: projectUuId.value
                })
                if (data.progress != '100%') {
                    calculateInfo.loading = true
                    calculateInfo.progress = data.progress
                    setTimeout(() => getCalculateProgress(), 3000)
                } else {
                    calculateInfo.loading = false
                    root.$message.success(<string>root.$i18n.t('projectWordCloud.calculateSuccess'))
                }
            } catch (error) {
                showError(error)
            }
        }

        const nlpUploadInfo = reactive({
            loading: false,
            isSuccess: false,
            before: (file: File) => {
                return uploadHandle(file, 5)
            },
            request: async (value: any) => {
                nlpUploadInfo.loading = true
                nlpUploadInfo.isSuccess = false
                try {
                    await importNlpApi({
                        file: value.file,
                        uuid: projectUuId.value
                    })
                    getNlpConfigDetail()
                    getCalculateProgress()
                    nlpUploadInfo.isSuccess = true
                    setTimeout(() => {
                        nlpUploadInfo.isSuccess = false
                    }, 3000)
                } catch (error) {
                    showError(error)
                }
                nlpUploadInfo.loading = false
            }
        })

        const exportNlpInfo = reactive({
            loading: false,
            export: async () => {
                if (!configObj.config.id) {
                    downloadTemplate(projectObj.details.project_name)
                    return
                }
                exportNlpInfo.loading = true
                try {
                    const data = await exportNlpApi({
                        uuid: projectUuId.value
                    })
                    exportFile(data, <string>root.$i18n.t('projectWordCloud.exportListXlsx', { name: projectObj.details.project_name }))
                } catch (error) {
                    showError(error)
                }
                exportNlpInfo.loading = false
            }
        })

        const downloadTemplate = async (fileName = root.$i18n.t('projectWordCloud.templateTitle')) => {
            try {
                const data = await exportNlpApi({ uuid: '' })
                exportFile(data, <string>root.$i18n.t('projectWordCloud.exportListXlsx', { name: fileName }))
            } catch (error) {
                showError(error)
            }
        }

        return {
            projectUuId,
            projectObj,
            configObj,
            EntityType,
            ProjectStatus,
            nlpUploadInfo,
            excelTypes,
            exportNlpInfo,
            downloadTemplate,
            calculateInfo
        }
    }
})
